import { Global, css } from '@emotion/core';
import React from 'react';

import { useTheme } from '../app.use/useTheme';
import { SystemBox } from '@whop/system/SystemBox';

function GlobalStyles() {
  const theme = useTheme();

  // @review: consider moving this to default theme rather than having it on every project to
  // override. Also consider using it as-is, each project must do its "reset work". And "html.li"
  // could work also like that.
  const htmlCoreCss = css`
    html {
      font-family: ${theme.fonts.base};
      font-size: 100%;
      font-weight: normal;
      font-style: normal;
      color: ${theme.colors.text};
      background-color: ${theme.colors['bg_body']};
    }
  `;

  const bodyCoreCss = css`
    body {
      margin: 0;
    }
  `;

  const customBody = theme.override.globalStyles.body;
  const bodyStyles = customBody ? [bodyCoreCss, customBody] : bodyCoreCss;

  const customHtml = theme.override.globalStyles.html;
  const htmlStyles = customHtml ? [htmlCoreCss, customHtml] : htmlCoreCss;
  return (
    <React.Fragment>
      <Global styles={bodyStyles as AnyInternalOnly} />
      <Global styles={htmlStyles as AnyInternalOnly} />
    </React.Fragment>
  );
}

export const GlobalStylesForExamples = GlobalStyles;

function AppBody(props: { children: React.ReactNode }) {
  // Note(minHeight): this allows to vertically center
  // const minHeight = `calc(100vh - ${runtimeDims.headerHeight}px - ${runtimeDims.footerHeight}px)`;
  return (
    <SystemBox
      as="main"
      css={css`
        margin: 0 auto;

        box-sizing: border-box;
        width: 100%;

        max-width: 100vw;
      `}
    >
      {props.children}
    </SystemBox>
  );
}

export function AppLayout(props: {
  body: React.ReactElement;
  header: React.ReactElement;
  footer: React.ReactElement;
}) {
  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <GlobalStyles />
      {props.header}
      <div>
        <AppBody>{props.body}</AppBody>
        {props.footer}
      </div>
    </div>
  );
}

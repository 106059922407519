import { BreakpointEnum } from '../pkg.theme/types';
import { useMediaQuery } from '@whop/responsive/useMediaQuery';

import { useTheme } from '../app.use/useTheme';
import { ResponsiveValues } from '@whop/system/types';
import { useUniversalCode } from '../app.context/universalCode';

type QueryTypeEnum = 'down' | 'up';

function useMediaIs(queryType: QueryTypeEnum, bx: BreakpointEnum): boolean {
  const theme = useTheme();
  const { ssrMatchMedia } = useUniversalCode();
  const value = theme.breakpoints[bx];
  const query = queryType === 'up' ? `(min-width: ${value}px)` : `(max-width: ${value}px)`;
  const defaultMatches = queryType === 'up';
  return useMediaQuery(query, { defaultMatches, ssrMatchMedia });
}

export function useMediaIsDown(bx: BreakpointEnum): boolean {
  return useMediaIs('down', bx);
}

export function useMediaIsUp(bx: BreakpointEnum): boolean {
  return useMediaIs('up', bx);
}

function useMatchedUpBreakpoint() {
  // const xsMatched = useMediaIsUp('xs');
  const xlMatched = useMediaIsUp('xl');
  const lgMatched = useMediaIsUp('lg');
  const mdMatched = useMediaIsUp('md');
  const smMatched = useMediaIsUp('sm');

  if (xlMatched) {
    return 'xl';
  }
  if (lgMatched) {
    return 'lg';
  }
  if (mdMatched) {
    return 'md';
  }
  if (smMatched) {
    return 'sm';
  }
  return 'xs';
}

export function useMatchedUpBreakpointIdx(): number {
  const matched = useMatchedUpBreakpoint();
  // NotePrototype: move to theme file or constants
  const breakpointsOrder = ['xs', 'sm', 'md', 'lg', 'xl'];
  const idx = breakpointsOrder.findIndex((val) => val === matched);
  if (idx === -1) {
    return 0;
  }
  return idx;
}

// @review: could be better typed to force from < to
export function useMatchBreakpointsRange(from: BreakpointEnum, to: BreakpointEnum): boolean {
  const fromMatched = useMediaIsUp(from);
  const toMatched = useMediaIsDown(to);
  return fromMatched && toMatched;
}

// export function useMatchBreakpointsUp(): ResponsiveValues<boolean> {
//   // isSmUp -- 600+
//   // isMdUp -- 900+
//   // isLgUp -- 1200+
//   // isXlUp -- 1900+
//   // usage:
//   // const [isSmUp, isMdUp, isLgUp, isXlUp] = useMatchBreakpointsUp();

//   // @todo: short-circuit when first is matched (and still adhere rule of hooks)
//   const smMatched = useMediaIsUp('sm');
//   const mdMatched = useMediaIsUp('md');
//   const lgMatched = useMediaIsUp('lg');
//   const xlMatched = useMediaIsUp('xl');
//   return [smMatched, mdMatched, lgMatched, xlMatched];
// }

// type ResponsiveRange = 0 | 1 | 2 | 3 | 4;

export function useGetResponsiveValues() {
  // @review: add different API to match all breakpoints at once
  const smDown = useMediaIsDown('sm');
  const mdDown = useMediaIsDown('md');
  const lgDown = useMediaIsDown('lg');
  const xlDown = useMediaIsDown('xl');

  return <T>(values: ResponsiveValues<T>): T => {
    const maxIdx = values.length - 1;
    const fallBackValue = values[0];
    if (smDown) {
      return fallBackValue;
    }
    // @review: we should prevent missing values with types or log a warning
    if (mdDown) {
      return values[Math.min(1, maxIdx)] ?? fallBackValue;
    }
    if (lgDown) {
      return values[Math.min(2, maxIdx)] ?? fallBackValue;
    }
    if (xlDown) {
      return values[Math.min(3, maxIdx)] ?? fallBackValue;
    }
    return values[Math.min(4, maxIdx)] ?? fallBackValue;
  };
}

export function useResponsiveValues<T>(values: ResponsiveValues<T>) {
  return useGetResponsiveValues()(values);
}

export const useResolveResponsiveFn = useGetResponsiveValues;

import { Container } from '@whys/app/lib/state';
import { FetchJsonFn } from '@whop/containers/types';

import { LOGIN_SYNC_LS_KEY } from '../app.constants/persist';
import { definePlainAppResource } from '../tmp.prototyping/appLevelResources';
import { httpResources } from '@whop/user';
import { PlainResource } from '@whop/resources/types';
import { mapProfile } from '@whop/profile/mapping';
import { defaultProfile } from '@whop/profile/model';
import { ProfileModel } from '@whop/profile/types';
import { CommonError } from '@whop/backend/types';
import { AppResourceContext } from '../app.types/state';

type Result<TInfo extends object = {}> = { ok: true } | { ok: false; info: TInfo };
type AsyncResult<TInfo extends object = {}> = Promise<Result<TInfo>>;
type VoidResult = void;

type LocalState = {
  isLogged: boolean;
};

type LocalProps = {
  fetchJson: FetchJsonFn;
  resourceContext: AppResourceContext;
  initialState?: Partial<LocalState>;
  initialProfile?: ProfileModel;
};

/**
 * @todo add clientPersistCache prop (same API as other caches and make it work with localStorage and on server)
 */
class LoginContainer extends Container<LocalState> {
  private state: LocalState;

  profile: PlainResource<ProfileModel>;

  constructor(private props: LocalProps) {
    super();

    this.state = { isLogged: props.initialState?.isLogged ?? false };

    const { resourceContext } = props;

    this.profile = definePlainAppResource(httpResources.profileDetail, {
      resourceContext,
      map: mapProfile,
      fallback: defaultProfile,
      initialValue: props.initialProfile,
    });

    // const { fetchJson } = this.props;
  }

  async loginUser(
    credentials: object
  ): Promise<
    | { ok: true }
    | {
        ok: false;
        type: 'error';
        error: 'passwordNotSet' | 'credentialsInvalid' | 'custom';
        html_message?: string;
      }
    | { ok: false; type: 'failure' }
  > {
    const { fetchJson } = this.props;
    const result = await fetchJson<void, Partial<CommonError>, object>(
      httpResources.userLogin,
      credentials
    );
    if (result.status === 'ok') {
      // this.setState({ isLogged: true });
      localStorage.setItem(LOGIN_SYNC_LS_KEY, 'true');
      return { ok: true };
    }
    if (result.status === 'error') {
      if (result.data?.code === 'PasswordNotSet') {
        return { ok: false, type: 'error', error: 'passwordNotSet' };
      }
      if (result.data?.detail) {
        return { ok: false, type: 'error', error: 'custom', html_message: result.data?.detail };
      }
      return { ok: false, type: 'error', error: 'credentialsInvalid' };
    }
    return { ok: false, type: 'failure' };
  }

  async logoutUser(): AsyncResult {
    const { fetchJson } = this.props;
    const result = await fetchJson({
      method: 'POST',
      url: httpResources.userLogout.url,
    });
    if (result.status === 'ok') {
      // this.setState({ isLogged: false });
      localStorage.setItem(LOGIN_SYNC_LS_KEY, 'false');
      return { ok: true };
    }
    return { ok: false, info: {} };
  }

  /**
   * Forces app state to be in login state, without backend involved.
   */
  forceLogin(): VoidResult {
    // NotePrototype(simon): this needs to fully reload appContainer, so for now we just reload the app.
    // Same for logout
    window.location.reload();
  }

  /**
   * Forces app state to be in logout state, without backend involved.
   */
  forceLogout(): VoidResult {
    // see forceLogin
    window.location.reload();
  }

  isLogged(): boolean {
    return this.state.isLogged;
  }
}

export { LoginContainer };

import { defineHttpResources } from '@whop/containers';
import { PageInfoModel, PageInfoPayload, SystemPageModel, SystemPagePayload } from './types';

export const httpResources = defineHttpResources({
  systemPages: { url: `/api/content/systempage/urls/` },
  staticPages: {
    url: '/api/content/staticpage/urls/',
  },
  pageInfo: (url: string) => ({
    url: `/api/url${url}`,
  }),
  systemPageDetail: (id: string) => ({ url: `/api/content/systempage/${id}/` }),
});

export function mapPageInfo(payload: PageInfoPayload): PageInfoModel {
  const id = String(payload.object_id);
  return {
    id,
    title: payload.title,
    description: payload.description,
    keywords: payload.keywords,
    model: payload.content_type.model,
    modelId: id,
    breadcrumbs: payload.breadcrumbs,
    canonicalLink: payload.url_canonical,
  };
}

export function mapSystemPage(payload: SystemPagePayload): SystemPageModel {
  const pageName = payload.identifier;
  return { pageName };
}

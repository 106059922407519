import { defineHttpResources } from '@whop/containers';
import {
  OrderProductItem,
  OrderProductPayload,
  OrderOverviewPayload,
  OrderOverviewModel,
  InvoiceOverviewModel,
  InvoiceOverviewPayload,
  OrderDetailModel,
  OrderDetailPayload,
  ShippingOptionPayload,
  ShippingOptionModel,
  PaymentOptionPayload,
  PaymentOptionModel,
  OrderOverviewSerialized,
  OrderDetailSerialized,
  InvoiceOverviewSerialized,
} from './types';
import { coerce } from '@whop/core/mapping';
import { mapBillingAddress, mapShippingAddress } from '@whop/business';
import { mapProfile } from '@whop/profile/mapping';

export const httpResources = defineHttpResources({
  // All orders
  // can be parametrized, see OrdersListView.tsx
  listOrders: { url: '/api/order/' },
  orderDetail: (id: string) => ({ url: `/api/order/${id}/` }),
  listInvoices: { url: '/api/order/invoice-new/' },
  createOrder: { url: `/api/order/`, method: 'POST' },
  paymentOptions: { url: '/api/checkout/payment-method/' },
  shippingOptions: { url: '/api/checkout/shipping-method/' },
  paymentOptionsByShippingId: (shippingId: string) => ({
    url: `/api/checkout/payment-method/?shipping_method=${shippingId}`,
  }),
  shippingOptionsByCountry: (country: string) => ({
    url: `/api/checkout/shipping-method/?country_code=${country}`,
  }),
});

export function mapOrderProduct(payload: OrderProductPayload): OrderProductItem {
  return {
    id: coerce.str(payload.id),
    variantId: coerce.str(payload.variant),
    quantity: coerce.num(payload.quantity, 0),
    quantityDelivered: coerce.num(payload.quantity_delivered, 0),
    prices: payload.prices,
  };
}

export function mapOrderOverview(payload: OrderOverviewPayload): OrderOverviewModel {
  return {
    id: coerce.str(payload.id),
    orderNumber: coerce.str(payload.erp_order_id),
    businessId: String(payload.shipping_address.id),
    items: payload.items.map(mapOrderProduct),
    prices: payload.prices,

    acceptDate: coerce.dateOrFallback(payload.time_created, null),
    sendDate: coerce.dateOrFallback(payload.date_dispatched, null),
    deliveryDate: coerce.dateOrFallback(payload.delivery_date, null),

    isOpen: payload.open,
    statusTitle: payload.info?.order_status?.short_name || '',
    /* eslint-disable no-restricted-globals */
    statusDetail: payload.info?.order_status?.name || '',
    statuses: payload.statuses || {},
    /* eslint-enable no-restricted-globals */
    statusType: payload.info?.order_status?.severity_type || 'UNKNOWN',
    createdBy: mapProfile(payload.profile),
    shippingAddress: mapShippingAddress(payload.shipping_address),
    children: payload.children.map(mapOrderOverview),
    note: payload.note,
    customerCode: payload.customer_custom_code,
    workerPersonalNumber: coerce.str(payload.worker_personal_number),
  };
}

export function readOrderOverview(serialized: OrderOverviewSerialized): OrderOverviewModel {
  return {
    ...serialized,
    acceptDate: coerce.dateOrFallback(serialized.acceptDate, null),
    sendDate: coerce.dateOrFallback(serialized.sendDate, null),
    deliveryDate: coerce.dateOrFallback(serialized.deliveryDate, null),
  };
}

export function readInvoiceOverview(serialized: InvoiceOverviewSerialized): InvoiceOverviewModel {
  return {
    ...serialized,
    dueDate: coerce.dateOrFallback(serialized.dueDate, null),
    createdDate: coerce.dateOrFallback(serialized.createdDate, null),
  };
}

export function mapOrderDetail(payload: OrderDetailPayload): OrderDetailModel {
  return {
    ...mapOrderOverview(payload),
    billingAddress: mapBillingAddress(payload.billing_address),
    profile: mapProfile(payload.profile),
    paymentMethod: mapPaymentOption(payload.payment_method),
    shippingMethod: mapShippingOption(payload.shipping_method),
    invoices: payload.invoices.map(mapInvoiceOverview),
    parentId: coerce.str(payload.parent_id),
  };
}

export function readOrderDetail(serialized: OrderDetailSerialized): OrderDetailModel {
  return {
    ...serialized,
    ...readOrderOverview(serialized),
  };
}

export function mapInvoiceOverview(payload: InvoiceOverviewPayload): InvoiceOverviewModel {
  return {
    id: coerce.str(payload.id),
    invoiceNumber: coerce.str(payload.variable_symbol),
    createdDate: coerce.dateOrFallback(payload.issued_dt || '', null),
    dueDate: coerce.dateOrFallback(payload.maturity_dt || '', null),

    priceGross: payload?.prices?.total_discount_price_gross,
    priceNet: payload?.prices?.total_discount_price_net,
    leftToPay: coerce.num(payload.price_to_pay_gross, 0),

    files: payload.files.map((url) => {
      // @prototype(name): no better API right now
      const name = url.split('/').pop() || url;
      // @prototype(id): no better API right now
      return { id: url, url, key: url, name };
    }),

    isActive: payload.is_active,
    /* eslint-disable no-restricted-globals */
    statusType: payload?.status?.severity_type || 'UNKNOWN',
    statusTitle: payload?.status?.short_name || '',
    /* eslint-enable no-restricted-globals */

    shippingAddress: mapShippingAddress(payload.shipping_address),
    orders: payload.orders.map(mapOrderOverview),
    businessId: coerce.str(payload.shipping_address.business_id),
    paymentMethod: '',
  };
}

export function mapShippingOption(payload: ShippingOptionPayload): ShippingOptionModel {
  return { ...payload, id: String(payload.id) };
}

export function mapPaymentOption(payload: PaymentOptionPayload): PaymentOptionModel {
  return { ...payload, id: String(payload.id) };
}

import { Container } from '@whys/app/lib/state';
import { EntityResource, defineEntityAppResource } from '../tmp.prototyping/appLevelResources';
import {
  PageInfoPayload,
  PageInfoErrorPayload,
  SystemPageModel,
  SystemPageNames,
  NiceUrlPageModelNames,
} from '@whop/niceurls/types';
import { httpResources, mapPageInfo, mapSystemPage } from '@whop/niceurls';
import { AppResourceContext } from '../app.types/state';
import { ParamResource } from '@whop/resources/types';
import { defineParamResource } from '@whop/resources/param';
import { ResourceFetchError } from '@whop/resources/errors';
import { PageInfoResult } from '../pkg.pages/types';
import { defineLoadablePage } from '../app.base/pages';
import { ConcretePageContext } from '../app.types/pages';
// import { NiceUrlPageContext } from '../app.types/pages';
// import { ArgumentTypes } from '@whop/utils/types';

const modelPageRegistry = {
  category: defineLoadablePage(() => import('../tsx.app.category/CategoryPage')),
  variant: defineLoadablePage(() => import('../tsx.app.product/ProductPage')),
  article: defineLoadablePage(() => import('../tsx.app.content/ArticlePage')),
  staticpage: defineLoadablePage(() => import('../tsx.app.content/ContentPage')),
  systempage: defineLoadablePage(() => import('../pkg.niceurls/SystemPage')),
};

const systempageRegistry = {
  // eshop >>
  homepage: defineLoadablePage(() => import('../tsx.app.eshop/HomePage')),
  cart: defineLoadablePage(() => import('../tsx.app.eshop/CartPage')),
  search: defineLoadablePage(() => import('../tsx.app.eshop/SearchPage')),
  'quick-order': defineLoadablePage(() => import('../tsx.app.eshop/QuickOrderPage')),
  // auth >>
  login: defineLoadablePage(() => import('../tsx.app.auth/LoginPage')),
  register: defineLoadablePage(() => import('../tsx.app.auth/RegisterPage')),
  'reset-password-form-email': defineLoadablePage(
    () => import('../tsx.app.auth/NewPasswordRequestPage')
  ),
  'reset-password-form-password': defineLoadablePage(
    () => import('../tsx.app.auth/NewPasswordConfirmPage')
  ),
  'set-password-form': defineLoadablePage(() => import('../tsx.app.auth/SetPasswordPage')),
  // manage >>
  orders: defineLoadablePage(() => import('../tsx.app.order/OrderListPage')),
  invoice: defineLoadablePage(() => import('../tsx.app.order/InvoiceListPage')),
  account: defineLoadablePage(() => import('../tsx.app.user/AccountPageView')),
  users: defineLoadablePage(() => import('../tsx.app.user/UserListPage')),
  // product >>
  favorite: defineLoadablePage(() => import('../tsx.app.product/FavoriteListPage')),
  watchdog: defineLoadablePage(() => import('../tsx.app.product/WatchdogPage')),
  // marketing >>
  articles: defineLoadablePage(() => import('../tsx.app.content/ArticleListPage')),
};

type LocalState = void;

type LocalProps = {
  resourceContext: AppResourceContext;
};

export class PageContainer extends Container<LocalState> {
  private state: LocalState;

  systemPages: EntityResource<SystemPageModel>;
  niceUrlInfos: ParamResource<PageInfoResult | null, [string]>;

  constructor(private props: LocalProps) {
    super();

    const { resourceContext } = props;
    this.systemPages = defineEntityAppResource(httpResources.systemPageDetail, {
      resourceContext,
      map: mapSystemPage,
    });

    this.niceUrlInfos = (() => {
      // @review: should we catch also the
      const { __fetchJson: fetchJson } = resourceContext;
      const getKey = (url: string) => `url:${url}`;

      return defineParamResource({
        name: 'niceUrls',
        cache: resourceContext.__appCache,
        instancesCache: resourceContext.__runtimeCache.getOrCreateCache('param'),
        options: resourceContext,
        getKey,
        defaultValue: null,
        resolve: async (url: string) => {
          const result = await fetchJson<PageInfoPayload, PageInfoErrorPayload>(
            httpResources.pageInfo(url)
          );
          if (result.status === 'ok') {
            const pageInfo = mapPageInfo(result.data);
            // @review cache also non-200? Only some of them are safe, some are temporary.
            return { status: 'ok', value: pageInfo };
          }
          if (result.status === 'error') {
            const { response, data } = result;
            const status = response && response.status;
            if (status === 404 || status === 403) {
              const redirectUrl = data?.redirect_url;
              if (redirectUrl) {
                return { status: 'fixable', value: { redirectUrl } };
              }
            }
          }
          throw new ResourceFetchError({ resourceName: getKey(url), response: result.response });
        },
      });
    })();
  }

  async prefetchPageInfoFromUrl(url: string) {
    await this.getPageInfoFromUrl(url);
  }

  async getPageInfoFromUrl(url: string): Promise<PageInfoResult> {
    const info = await this.niceUrlInfos(url).getOrFetch();
    if (!info) {
      return { status: 'unloaded' };
    }
    return info;
  }

  async resolveInitialProps(pageName: SystemPageNames, options: { ctx: ConcretePageContext }) {
    const loadableComponent = systempageRegistry[pageName];
    if (!loadableComponent) {
      return null;
    }
    const PageComponent = await loadableComponent.load();
    const getInitialProps = PageComponent ? PageComponent.getInitialProps : null;
    if (!getInitialProps) {
      return null;
    }
    return await getInitialProps(options.ctx);
  }

  async getSystemPageInfo(pageName: SystemPageNames, options: { ctx: ConcretePageContext }) {
    const systemPage = await this.systemPages(pageName).getOrFetch();
    if (systemPage) {
      const { pageName } = systemPage;
      const pageProps = await this.resolveInitialProps(pageName, options);
      return {
        pageName,
        pageProps,
      };
    }
    return null;
  }

  async getNiceUrlPageInfo(options: {
    pathname: string;
    search: string;
    ctx: Omit<ConcretePageContext, 'pageInfo'>;
  }) {
    const result = await this.getPageInfoFromUrl(options.pathname);
    if (result.status === 'ok') {
      const pageInfo = result.value;
      const PageComponent = await modelPageRegistry[pageInfo.model].load();
      const pageProps = PageComponent.getInitialProps
        ? await PageComponent.getInitialProps({ ...options.ctx, pageInfo, pageContainer: this })
        : {};
      return {
        status: 'ok' as const,
        pageInfo,
        pageProps,
      };
    }
    if (result.status === 'fixable') {
      const redirectInfo = result.value;
      return {
        status: 'redirect' as const,
        redirectInfo,
      };
    }
    return {
      status: 'notFound' as const,
    };
  }

  async prefetchPage(options: {
    pathname: string;
    // note: search prefetch doesnt work
    search: string;
    ctx: Omit<ConcretePageContext, 'pageInfo'>;
  }) {
    // this should resolve url + fetch all data for given url
    await this.getNiceUrlPageInfo(options);
  }

  selectSystemPageComponent(pageName: SystemPageNames) {
    return systempageRegistry[pageName];
  }

  selectNiceUrlComponent(pageName: NiceUrlPageModelNames) {
    return modelPageRegistry[pageName];
  }
}

export type PageContainerType = PageContainer;
